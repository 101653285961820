import './memes.css';
import patbench from './images/patbench.jpg';
import flatsquid from './images/flatsquid.jpg';
import patbrain from './images/patbrainexplode.jpg';
function Memes (){
    return (
        <>
         <section class="gallery">
        <div class="meme">
            <img src={patbench} alt="Meme 1" />
            <div class="caption">That feeling when the WiFi reconnects.</div>
        </div>
        <div class="meme">
            <img src={flatsquid} alt="Meme 2" />
            <div class="caption">Me trying to adult on a daily basis.</div>
        </div>
        <div class="meme">
            <img src={patbrain} alt="Meme 3" />
            <div class="caption">When you realize it's Monday again...</div>
        </div>
       
    </section>
    <div style={{ height: 500 }}></div>
        </>
    )
}

export default Memes;