import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import About from './About';
import Header from './Header';
import Code from './Code';
import { ConfigProvider, theme } from 'antd';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Examples from './Examples';
import TicketSys from './TicketSys';
import Memes from './Memes';

const { darkAlgorithm } = theme;
const root = ReactDOM.createRoot(document.getElementById('root'));
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDBi3d2HuY7yF3TxCiMmADnTCJqtLCcbXQ",
  authDomain: "aaronalnutt.firebaseapp.com",
  projectId: "aaronalnutt",
  storageBucket: "aaronalnutt.appspot.com",
  messagingSenderId: "829347305680",
  appId: "1:829347305680:web:c19f69b3c034ffab4426d5",
  measurementId: "G-0C8GSHPHZH"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/examples",
    element: <Examples />,
  },
  {
    path: "/ticket",
    element: <TicketSys />,
  },
  {
    path: "/code",
    element: <Code />
  },
  {
    path: "/memes",
    element: <Memes />
  }
]);

root.render(
  <React.StrictMode>
    <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#5cff17',
            fontSize: 20
          },
          algorithm: darkAlgorithm
        }}
      >
    <div className="App">
      <Header />
      
        <RouterProvider router={router} />
      
    </div>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
